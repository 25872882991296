

// Burgermenu stuff
const MENUBUTTON = document.getElementById('menuButton');
const MENUBUTTONFIXED = document.getElementById('menuButtonFixed');
const MENUBUTTONCLOSE = document.getElementById('menuButtonClose');
const NAVWRAPPER = document.getElementById('navWrapper');
const SIDENAV = document.getElementById('sideNavigation');
const MAINCONTENT = document.getElementById('wrapper');
const PATHES = document.querySelectorAll('path');
MENUBUTTON.onclick = openMenu;

function openMenu() {
    // console.log('click')
    SIDENAV.classList.add('show');
    NAVWRAPPER.classList.remove('hide');
    setTimeout(function() {
        const e = document.querySelectorAll('.animate').forEach(element => {
            element.classList.add('fade-in');
    })},250);
    noScroll();
};

MENUBUTTONCLOSE.onclick = function() {
    SIDENAV.classList.remove('show');
    NAVWRAPPER.classList.add('hide');
    const e = document.querySelectorAll('.animate').forEach(element => {
        element.classList.remove('fade-in');
    });
    noScroll();
}

function closeMenu() {
    SIDENAV.classList.remove('show');
    NAVWRAPPER.classList.add('hide');
    const e = document.querySelectorAll('.animate').forEach(element => {
        element.classList.remove('fade-in');
    });
    noScroll();
};


// Animate on scroll stuff
const bottomDiff = 50;
const aoselements = document.querySelectorAll('.aos');

window.addEventListener('scroll', () => {
    aoselements.forEach(aoselement => {
        // Class Manager
        const status = aosIsInView(aoselement);
        aoselement.classList.add('aosAnimate')
        aosManageClass(aoselement, status, 'aos');
        showPageScroller();
    });

    PATHES.forEach(path => {
        startDraw(path);
    })

});

PATHES.forEach(path => {
    startDraw(path);
})

function aosManageClass(element, status, classname) {
    if(status) {
        element.classList.remove(classname);
    };
}

function aosIsInView(element) {
    const bordersElements = element.getBoundingClientRect();
    return bordersElements.top <= (window.innerHeight || document.documentElement.clientHeight) - bottomDiff &&
        bordersElements.left >= 0 &&
        bordersElements.right <= (window.innerWidth || document.documentElement.clientWidth);
};




// Modal Handling...

// Open Start Modal after page load
// window.onload = function() {

//     if(document.readyState != 'loading') {
//         const MODALCONTAINER = document.getElementById('modalStartup');
//         setTimeout(function() { 
//             MODALCONTAINER.classList.add('modalShow');
//             noScroll();
//         },200); 
//     } else {
//         console.log('loading...')
//     };
// };

// define EventListeners

// Startup Modal closing on click
const MODALSTARTUPCLOSE = document.getElementById('modalCloseStartup').addEventListener('click', () => {
    modalClose('modalStartup');
    setTimeout(function () {
        PATHES.forEach(path => {
            path.classList.toggle('draw')
        })},500);
});

const MODALSTARTUPREADMORE = document.getElementById('modalStartupReadMore').addEventListener('click', () => {
    modalClose('modalStartup');
    setTimeout(function() {
        scrollToAnchor('claim', 10)
    },200);
})

const MODALSTARTUPGOTO = document.getElementById('modalStartupOrder').addEventListener('click', () => {
    modalClose('modalStartup');
    setTimeout(function() {
        scrollToAnchor('products', 50)
    },200);
});

const PAGEDOWN = document.getElementById('pageDown').addEventListener('click', () => {
    modalClose('modalStartup');
    scrollToAnchor('products', 50)
    noScroll()
});

// Menubuttons quick n dirty

const MENUMATERIAL = document.getElementById('menuMaterial').addEventListener('click', () => {
    closeMenu();
    scrollToAnchor('MaterialSourcing', 50)

});

const MENUPRODUCT = document.getElementById('menuShop').addEventListener('click', () => {
    closeMenu();
    scrollToAnchor('products', 50)

});

const MENUNEWSLETTER = document.getElementById('menuNewsletter').addEventListener('click', () => {
    closeMenu();
    scrollToAnchor('newsletter', 50)

});



const MODALORDERSUCCESSCLOSE = document.getElementById('modalCloseOrderSuccess').addEventListener('click',() => {
    modalClose('modalOrderSuccess')
});

const MODALORDERERRORCLOSE = document.getElementById('modalCloseOrderError').addEventListener('click',() => {
    modalClose('modalOrderError')
});

const lightBox = document.querySelector('#modalCostDetails');
const lightBoxClose = lightBox.querySelector('.lightBox__header').addEventListener('click', () => {
    modalClose('modalCostDetails')
})

// const lightBoxOpen = document.querySelector('#modalCostDetailsOpen').addEventListener('click', () => {
//     modalOpen('modalCostDetails')
// })

// const lightBoxSecondOpen = document.querySelector('#modalCostDetailsSecondOpen').addEventListener('click', () => {
//     console.log(document.querySelector('#modalCostDetailsSecondOpen'))
//     modalOpen('modalCostDetails')
// })



// toggleClasses

const toggleShow = function(button, element) {
    const open = document.getElementById(button).addEventListener('click', () => {
        const close = document.getElementById(element).classList.toggle('show');
        noScroll();
    });
};

//claudia
toggleShow('buttonMoreClaudia', 'personDetailsClaudia');
toggleShow('buttonMoreClaudiaClose', 'personDetailsClaudia')
//stephanie
toggleShow('buttonMoreStephanie','personDetailsStephanie');
toggleShow('buttonMoreStephanieClose', 'personDetailsStephanie');


// get Product and Images
const PRODUCT = document.getElementById('jacketNaira');

//change products picture srcset
function productPhotoSrcset(product, productName) {
    const baseurl = "./assets/img/"
    const productPhoto = product.querySelector('picture').childNodes.forEach((element) => {
        if(element.localName === 'source') {
            const size = element.dataset.size
            const newsrc = baseurl+size+productName+(".jpg")
            element.srcset = newsrc;
        } else if (element.localName === 'img') {
            const newsrc = baseurl+"small"+productName+(".jpg")
            element.src=newsrc;
        }
    });
};

function productPhotoRemoveActive() {
    const productActiveThumbnail = PRODUCT.querySelector('.active')
    productActiveThumbnail.classList.remove('active')
}

// thumbnails on click
const productThumbnailClick = PRODUCT.querySelector('.productThumbnails').childNodes.forEach((e) =>  {
    
    if(e.nodeName === "IMG") {
        const dataProduct = e.dataset.product;
        e.addEventListener('click', () => {
            productPhotoSrcset(PRODUCT, dataProduct)
            productPhotoRemoveActive()
            e.classList.add('active')
        });
    }
})

// productPhotoSrcset(PRODUCT, 'Naira_1')

// console.log(productPhoto)
// // console.log(productPhoto.getElementsByTagNames("*"));

function modalClose(id) {
    const modalToClose = document.getElementById(id);
    modalToClose.classList.remove('modalShow');
    noScroll();
};

function modalOpen(id) {
    const modalToOpen = document.getElementById(id);
    modalToOpen.classList.add('modalShow');
    noScroll();
}


// change window url
function scrollToAnchor(anchor, offset) {
    const TARGETANCHOR = document.getElementById(anchor);
    window.scrollTo(TARGETANCHOR.offsetLeft,TARGETANCHOR.offsetTop - offset);
};


// Start Draw if Visible
function startDraw(element) {
    //Get Hero bottom
    const hero = document.getElementById('hero').getBoundingClientRect();
    if(hero.bottom <= 200) {

        element.classList.remove('draw');
    }
    else {
        element.classList.add('draw');
    }

}

function noScroll() {
    document.body.classList.toggle('noScroll');
}

// scroll to top
function showPageScroller() {
    const PAGEUPBUTTONWRAPPER = document.getElementById('pageUpWrapper');
    const PAGEDOWNBUTTONWRAPPER = document.getElementById('pageDownWrapper');
    const MENUBUTTONPOS = document.getElementById('menuButton').getBoundingClientRect();
    const HEROHEIGHT = document.getElementById('hero').offsetHeight
    const POSITION = MENUBUTTONPOS.bottom;
    if(POSITION <= 0) {
        PAGEUPBUTTONWRAPPER.classList.add('show');
    } else {
        PAGEUPBUTTONWRAPPER.classList.remove('show');
    }
    if(HEROHEIGHT + POSITION <= 0) {
        PAGEDOWNBUTTONWRAPPER.classList.add('show');
    } else {
        PAGEDOWNBUTTONWRAPPER.classList.remove('show');
    }
}

function showFixedNavbar() {
    const FIXEDNAVBAR = document.getElementById('navbarFixed')
    const HEROPOS = document.getElementById('hero').getBoundingClientRect();
    const POSITION = HEROPOS.bottom;
    if(POSITION <= 0) {
        FIXEDNAVBAR.classList.remove('hide');
    } else {
        FIXEDNAVBAR.classList.add('hide');
    }  
}

const SCROLLTOTOP = document.getElementById('pageUp').addEventListener('click', () => {
    window.scrollTo(0,0);
});

// language selector

// const LANGCHECKBOX = document.getElementById('selectLang').addEventListener('click', (element) => {
//     switchLang();

// });



function switchLang() {
    const SWITCHLANG = document.querySelectorAll('.content');
    SWITCHLANG.forEach(e => {
        e.classList.toggle('show');
    });
};


// ORDER API
const BUTTONORDER = document.getElementById('submit');
BUTTONORDER.onclick = SubForm;

function SubForm (){
    $.ajax({
        url:"https://api.apispreadsheets.com/data/9181/",
        type:"post",
        data:$("#news").serializeArray(),
        success: function(){
            modalOpen('modalOrderSuccess')
        },
        error: function(){
            modalOpen('modalOrderError')
        }
    });
}